@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap');

.bg {
  animation: slide 0s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #6c3 50%, #09f 50%);
  bottom: 0;
  left: -50%;
  opacity: 0.3;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 10s;
}

.bg3 {
  animation-duration: 10s;
}

/* @keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
} */

* {
  margin: 0;
  padding: 0;
  color: #ececec;
}

body {
  font-family: "Roboto";
  background-color: #ececec;

  /* background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147)); */

  margin: 0;
}

header {
  margin: 0 auto;
  text-align: center;
  display: block;
  z-index: 99;
}

.categorias {
  padding-top: 5px;
  margin-top: -5px;

}

h2 {
  color: #000;
  font-family: "Roboto";
  position: relative;
  top: 0;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: .03em;
  z-index: 2;
}

main {
  display: inline-block;
  width: 100%;
  margin-top: 0px;
}

.content {
  padding: 10px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky+.content {
  padding-top: 102px;
}

.ontop {
  width: 100%;
  height: 10px;
  background-color: #32338e;
}

#myHeader {
  z-index: 999;
}

.progress-bar {
  /*position: fixed;*/
  top: 0;
  left: 0;
  width: 0;
  height: 10px;
  background-color: #32a457;
  transition: width .1s;
  transition-timing-function: ease-out;
  -webkit-transition: width .1s;
  -webkit-transition-timing-function: ease-out;
  z-index: 1000;
}

.logo-container {
  margin-bottom: 0;
  background-color: #FFF;
}

.matrix {
  width: 100%;
  height: 280px;
  z-index: -1;
}

.matrix {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  font-family: Courier, "Lucida Console", monospace;
}

.matrix>.entity {
  position: absolute;
  animation-name: lightoff;
  animation-duration: 4s;
}

@keyframes lightoff {
  from {
    color: #8F8;
    font-weight: bold;
    text-shadow: 0 0 5px #0A0;
  }

  to {
    color: #030;
    font-weight: lighter;
    text-shadow: none;
  }
}

.logo {
  width: 300px;
  margin: 8px 0 10px 0;
  z-index: 999;
}

.logo:hover {
  filter: brightness(1.1);
}

nav li {
  font-size: 1rem;
  list-style: none;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
  z-index: 99;
  padding-left: 10px;
  padding-right: 10px;
}

nav a {
  color: black;
  text-decoration: none;
}

.nav-item::after {
  display: block;
  margin: 5px auto;
  text-align: center;
  content: "";
  align-self: center;
  width: 15px;
  height: 5px;
  color: #ececec;
  text-decoration: none;
  background-color: #32338e;
  transition: 0.4s;
}

.nav-item:hover::after {
  content: "";
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #32a457;
  z-index: 1;
  transition: 0.3s;
}

.navbar {
  padding: 0;
  min-height: 70px;

  /*background-color: rgba(255, 255, 255, 0.6);*/

  backdrop-filter: blur(15px);

  display: flex;
  justify-content: center;
  align-items: center;

}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  text-transform: uppercase;
  text-decoration: none;
}

.nav-menu a{
  text-decoration: none;
}

/*.nav-branding {
  font-size: 2rem;
}*/

.nav-link {
  /* color: #32A457; */
  font-weight: bold;
  letter-spacing: .1rem;
  color: #32338e;
  transition: 0.7s ease;
}

.nav-link:hover {
  /* color: #32338e; */
  color: #32A457;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #32338e;
}

.geral {
  /* margin-left: 8%;
  margin-right: 5%; */
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 25px;

  padding: 0 60px;

  border-radius: 50px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  background-color: #FFF;

  width: 1000px;

  background-color: rgba(255, 255, 255, 0.8);

  backdrop-filter: blur(15px);

}

.container-func {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;

  width: 100%;
  max-width: 100%;
}

.titulo-div {
  display: flex;
  align-items: center;
  font-size: 2rem;
  margin-bottom: 30px;
  margin-top: 50px;
  padding-left: 15px;
}

.titulo-div::before {
  content: "";
  display: inline-block;
  background-image: url("imagens/marker.svg");
  background-size: 19px 19px;
  width: 19px;
  height: 19px;
  margin-right: 30px;
}

.titulo {
  align-self: center;
  font-family: "Roboto";
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #32338e;
}

.func {
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(1);
  /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
  /* Microsoft Edge and Firefox 35+ */
  width: 180px;
  text-align: center;
  margin-bottom: 60px;
  display: inline-block;
  /* margin-right: 25px; */
  transition: 0.4s;
}

.icon {
  width: 160px;
  height: 160px;
  object-fit: cover;
  border-radius: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.func:hover {
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(0);
  /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(0);
  /* Microsoft Edge and Firefox 35+ */
  color: #ececec;
  transition: 0.4s;
}

h2 {
  transition: 0.5s;
}

h2::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0px;
  left: -3px;
  background-color: #32338e;
  background-size: 5px 22x;
  width: 5px;
  height: 22px;
  margin-right: 5px;
  z-index: -1;
}

.func:hover h2::before {
  display: inline-block;
  position: absolute;
  top: 0px;
  left: -3px;
  background-color: #32338e;
  background-size: 5px 22px;
  width: 105%;
  height: 22px;
  margin-right: 5px;
  transition: 0.4s;
  z-index: -1;
  color: #ececec !important;
}

.func:hover h2 {
  color: #ececec !important;
  transition: 0.5s;
}

.func p {
  color: black;
}

.icon-titulo {
  margin-top: 200px;
}

@media (max-width: 1024px) {
  nav a {
    color: white;
    text-decoration: none;
    padding: 20px 10% 20px 10%;
  }

 


  



  main {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .geral {
    /* margin: 0; */
    margin-left: 0px;
    max-width: 65vw;
    margin: 15px auto;

  }

  .titulo-div {
    margin-left: 10px;
  }

  .container-func {
    justify-content: center;
  }

  .func {
    filter: gray;
    /* IE6-9 */
    -webkit-filter: grayscale(1);
    /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1);
    /* Microsoft Edge and Firefox 35+ */
    z-index: -1;
    /* margin-right: 20px; */
  }

  .icon {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .func::after {
    left: -5px;
  }

  .func:hover:after {
    left: -5px;
    width: 108%;
  }
}